var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-with-tabs" },
          [
            _c(
              "div",
              {
                staticClass: "top-page-header page-header-with-banner",
                attrs: { lang: _vm.locale },
              },
              [
                _c("div", { staticClass: "head-title top-space" }),
                _c(
                  "v-tabs",
                  {
                    staticClass: "custom-tab fixed-tab",
                    attrs: { "background-color": "transparent" },
                    model: {
                      value: _vm.tab,
                      callback: function ($$v) {
                        _vm.tab = $$v
                      },
                      expression: "tab",
                    },
                  },
                  _vm._l(_vm.items, function (item) {
                    return _c("v-tab", { key: item }, [
                      _vm._v(" " + _vm._s(item) + " "),
                    ])
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "v-container",
              {
                staticClass: "v-main-content automation-top  stepper-template",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        [
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 rounded-lg extra-padding",
                                  attrs: { flat: "", elevation: "3" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 v-flex-right" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-search-with-btn w-md-100",
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "mdi-magnify",
                                              label: _vm.$t(
                                                "labels.filterItem"
                                              ),
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.searchAR,
                                              callback: function ($$v) {
                                                _vm.searchAR = $$v
                                              },
                                              expression: "searchAR",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "rounded-lg",
                                              attrs: {
                                                depressed: "",
                                                color: "primary",
                                              },
                                              on: { click: _vm.callCreateRule },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("aRules.createAR")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("CreateRule", {
                                            attrs: {
                                              fromRulesPage:
                                                _vm.dataToCreateRule,
                                            },
                                            on: {
                                              fromCreateRule: function (
                                                $event
                                              ) {
                                                return _vm.dataFromCreateRule(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { staticStyle: { display: "block" } },
                                        [
                                          _c("v-data-table", {
                                            staticClass:
                                              "elevation-0 mt-4 table-style-3",
                                            attrs: {
                                              search: _vm.searchAR,
                                              headers: _vm.arHeaders,
                                              items: _vm.arItems,
                                              "items-per-page": 10,
                                              "item-key": "_id",
                                              loading: _vm.arLoading,
                                              "loading-text":
                                                "Loading... Please wait",
                                              "fixed-header": "",
                                              "no-data-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                              "no-results-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.sno",
                                                  fn: function (ref) {
                                                    var index = ref.index
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(index + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.actions",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      item.arStatus == "RUNNING"
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mr-2",
                                                              attrs: {
                                                                small: "",
                                                                color:
                                                                  "primary",
                                                                dark: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.editARRule(
                                                                    item
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-pencil-outline "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "primary",
                                                            dark: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteARRule(
                                                                item._id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-delete-outline "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item.disable",
                                                  fn: function (ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function (
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "div",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticStyle: {
                                                                              width:
                                                                                "min-content",
                                                                            },
                                                                          },
                                                                          "div",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        item.arStatus ==
                                                                        "RUNNING"
                                                                          ? _c(
                                                                              "v-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "borderedSwitch",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  "true-value":
                                                                                    "RUNNING",
                                                                                  "false-value":
                                                                                    "DISABLED",
                                                                                },
                                                                                on: {
                                                                                  change: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.disableARRule(
                                                                                      item._id
                                                                                    )
                                                                                  },
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    item.arStatus,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      "arStatus",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "item.arStatus",
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        item.arStatus ==
                                                                        "DISABLED"
                                                                          ? _c(
                                                                              "v-switch",
                                                                              {
                                                                                staticClass:
                                                                                  "borderedSwitch",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  "true-value":
                                                                                    "RUNNING",
                                                                                  "false-value":
                                                                                    "DISABLED",
                                                                                },
                                                                                on: {
                                                                                  change: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.enableARRule(
                                                                                      item._id
                                                                                    )
                                                                                  },
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    item.arStatus,
                                                                                  callback: function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      "arStatus",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "item.arStatus",
                                                                                },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          item.arStatus ==
                                                          "RUNNING"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "aRules.disable"
                                                                    )
                                                                  )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          item.arStatus ==
                                                          "DISABLED"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "aRules.enable"
                                                                    )
                                                                  )
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.selectedARItems,
                                              callback: function ($$v) {
                                                _vm.selectedARItems = $$v
                                              },
                                              expression: "selectedARItems",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "pa-4 rounded-lg extra-padding",
                                  attrs: { flat: "", elevation: "3" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0 v-flex-right" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-search-with-btn w-md-100",
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "append-icon": "mdi-magnify",
                                              label: _vm.$t(
                                                "labels.filterItem"
                                              ),
                                              "single-line": "",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value: _vm.searchLogs,
                                              callback: function ($$v) {
                                                _vm.searchLogs = $$v
                                              },
                                              expression: "searchLogs",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { staticStyle: { display: "block" } },
                                        [
                                          _c("v-data-table", {
                                            staticClass:
                                              "elevation-0 mt-4 table-style-3",
                                            attrs: {
                                              search: _vm.searchLogs,
                                              headers: _vm.logHeaders,
                                              items: _vm.logItems,
                                              "items-per-page": 10,
                                              "item-key": "_id",
                                              loading: _vm.logLoading,
                                              "loading-text":
                                                "Loading... Please wait",
                                              "fixed-header": "",
                                              "no-data-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                              "no-results-text": _vm.$t(
                                                "kai.showDataText"
                                              ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.sno",
                                                  fn: function (ref) {
                                                    var index = ref.index
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(index + 1) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.selectedARlogs,
                                              callback: function ($$v) {
                                                _vm.selectedARlogs = $$v
                                              },
                                              expression: "selectedARlogs",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("DecisionAlert", {
                    attrs: {
                      DecisionAlert: _vm.decisionAlertFlag,
                      DecisionAlertTitle: _vm.decisionAlertTitle,
                      DecisionAlertMessage: _vm.decisionAlertMessage,
                      DecisionAlertData: _vm.decisionAlertData,
                    },
                    on: {
                      emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                      emitDecisionAlertOkClicked: function ($event) {
                        return _vm.decisionOkAction($event)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { persistent: "", "max-width": "450" },
                          model: {
                            value: _vm.infoAlertFlag,
                            callback: function ($$v) {
                              _vm.infoAlertFlag = $$v
                            },
                            expression: "infoAlertFlag",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "headline grey lighten-2" },
                                [_vm._v(" " + _vm._s(_vm.infoAlertTitle) + " ")]
                              ),
                              _c("v-card-text", [
                                _c("div", [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.appleMsgs, function (msg, i) {
                                      return _c("li", { key: i }, [
                                        _vm._v(_vm._s(msg)),
                                      ])
                                    }),
                                    0
                                  ),
                                ]),
                              ]),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.infoAlertFlag = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("infoAlert.ok")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }