<template flex>
  <div class="dashboard">
    <v-app>
      <!-- Sizes your content based upon application components -->
      <div class="inner-container only-heading-with-tabs">

        <div class="top-page-header page-header-with-banner" :lang="locale">
          <div class="head-title top-space" >
            <!-- <h1 :lang="locale">{{ $t('pages.automationRule') }}</h1> -->
          </div>
          <v-tabs v-model="tab" background-color="transparent" class="custom-tab fixed-tab">
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </div>

        <!-- Provides the application the proper gutter -->
        <v-container fluid class="v-main-content automation-top  stepper-template">
          <!-- If using vue-router -->
          <template>
            <div>
              <v-tabs-items v-model="tab">

                <v-tab-item>
                  <v-card flat elevation="3" class="pa-4 rounded-lg extra-padding">

                    <v-card-text class="pa-0 v-flex-right">

                      <div class="v-search-with-btn w-md-100">

                        <v-text-field
                          v-model="searchAR"
                          append-icon="mdi-magnify"
                          :label="$t('labels.filterItem')"
                          hide-details
                        ></v-text-field>
                        <v-btn
                          depressed
                          class="rounded-lg"
                          color="primary"
                          @click="callCreateRule">
                          {{ $t('aRules.createAR') }}
                        </v-btn>
                        <CreateRule
                          @fromCreateRule="dataFromCreateRule($event)"
                          :fromRulesPage="dataToCreateRule"
                        ></CreateRule>
                      </div>
                    </v-card-text>
                    
                  
                    <v-card-text>
                      <v-row style="display: block">
                        <v-data-table
                          :search="searchAR"
                          v-model="selectedARItems"
                          :headers="arHeaders"
                          :items="arItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4 table-style-3"
                          item-key="_id"
                          :loading="arLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          :no-data-text="$t('kai.showDataText')"
                          :no-results-text="$t('kai.showDataText')"
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                                {{index + 1}}
                          </template>
                          <template v-slot:item.actions="{ item }">
                                <v-icon
                                  small
                                  class="mr-2"
                                  color="primary"
                                  @click="editARRule(item)"
                                  dark
                                  v-if="item.arStatus == 'RUNNING'"
                                >
                                  mdi-pencil-outline
                                </v-icon>
                                <v-icon
                                  class="mr-2"
                                  color="primary"
                                  @click="deleteARRule(item._id)"
                                  dark
                                >
                                  mdi-delete-outline
                                </v-icon>
                          </template>
                          <template v-slot:item.disable="{ item }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <div
                                  v-on="on"
                                  v-bind="attrs"
                                  style="width: min-content"
                                >
                                  <v-switch
                                    v-if="item.arStatus == 'RUNNING'"
                                    v-model="item.arStatus"
                                    color="primary"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                     class="borderedSwitch"
                                    @change="disableARRule(item._id)"
                                  ></v-switch>
                                  <v-switch
                                    v-if="item.arStatus == 'DISABLED'"
                                    v-model="item.arStatus"
                                    color="primary"
                                    true-value="RUNNING"
                                    false-value="DISABLED"
                                     class="borderedSwitch"
                                    @change="enableARRule(item._id)"
                                  ></v-switch>
                                </div>
                              </template>
                              <span v-if="item.arStatus == 'RUNNING'">{{
                                $t('aRules.disable')
                              }}</span>
                              <span v-if="item.arStatus == 'DISABLED'">{{
                                $t('aRules.enable')
                              }}</span>
                            </v-tooltip>
                          </template>
                        </v-data-table>
                      </v-row>
                    </v-card-text>
                    
                  </v-card>

                </v-tab-item>

                <v-tab-item>
                  <v-card flat elevation="3" class="pa-4 rounded-lg extra-padding">
                    <v-card-text class="pa-0 v-flex-right">
                      <div class="v-search-with-btn w-md-100">
                        <v-text-field
                            v-model="searchLogs"
                            append-icon="mdi-magnify"
                            :label="$t('labels.filterItem')"
                            single-line
                            hide-details
                          ></v-text-field>
                      </div>
                    </v-card-text>

                    <v-card-text>
                      <v-row style="display: block">
                        <v-data-table
                          :search="searchLogs"
                          v-model="selectedARlogs"
                          :headers="logHeaders"
                          :items="logItems"
                          :items-per-page="10"
                          class="elevation-0 mt-4 table-style-3"
                          item-key="_id"
                          :loading="logLoading"
                          loading-text="Loading... Please wait"
                          fixed-header
                          :no-data-text="$t('kai.showDataText')"
                          :no-results-text="$t('kai.showDataText')"
                        >
                          <template v-slot:[`item.sno`]="{ index }">
                                {{index + 1}}
                          </template>
                          <!-- <template v-slot:item.logStatus="{ item }">   
                                <span v-if="item.logStatus == 'CREATED'" style="color:black">{{ item.logStatus }}</span>                                                       
                                <span v-else-if="item.logStatus == 'SUCCESS'" style="color:black">COMPLETED</span>
                                <span v-else-if="item.logStatus == 'SLACKSENT' && item.data && item.data.ok" style="color:darkgreen">{{ item.logStatus }}</span>
                                <span v-else-if="item.logStatus == 'SLACKSENT' || item.logStatus == 'SLACKFAIL'" style="color:crimson; text-decoration-line: underline; cursor: pointer" @click="showLogStatusMsg(item)">{{ item.logStatus }}</span>                                
                                <span v-else-if="item.logStatus == 'EMAILSENT' && item.data && item.data.statusCode == 202" style="color:darkgreen">{{ item.logStatus }}</span>
                                <span v-else-if="item.logStatus == 'EMAILSENT' || item.logStatus == 'EMAILFAIL'" style="color:crimson; text-decoration-line: underline; cursor: pointer" @click="showLogStatusMsg(item)">{{ item.logStatus }}</span> 
                                <span v-else-if="item.data && item.data.error" style="color:crimson; text-decoration-line: underline; cursor: pointer" @click="showLogStatusMsg(item)">{{ item.logStatus }}</span>
                                <span v-else style="color:darkgreen">{{ item.logStatus }}</span>
                          </template> -->
                        </v-data-table>
                      </v-row>
                    </v-card-text>

                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </div>
            <DecisionAlert
              :DecisionAlert="decisionAlertFlag"
              :DecisionAlertTitle="decisionAlertTitle"
              :DecisionAlertMessage="decisionAlertMessage"
              :DecisionAlertData="decisionAlertData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />
            <div class="text-center">
            <v-dialog
              v-model="infoAlertFlag"
              persistent
              max-width="450"
            >
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  {{ infoAlertTitle }}
                </v-card-title>

                <v-card-text>
                  <div><ul><li v-for="(msg,i) in appleMsgs" :key="i">{{ msg }}</li></ul></div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="infoAlertFlag=false"
                  >
                    {{$t('infoAlert.ok')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            </div>
          </template>
        </v-container>
      </div>
    </v-app>
  </div>
</template>

<script>
import {
  GET_ALL_RULES,
  GET_ALL_LOGS,
  DISABLE_AR_RULE,
  DELETE_AR_RULE,
  ENABLE_AR_RULE,
} from '../utils/arrules-queries';
import CreateRule from '../components/arrules/createrule.vue';
import DecisionAlert from '../components/DecisionAlert';
import { updateCLF } from '../utils/util'

export default {
  name: 'Rules',
  components: {
    CreateRule,
    DecisionAlert,
  },
  data() {
    return {
      locale: 'en',
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertData: {},
      tab: null,
      searchAR: '',
      searchLogs: '',
      selectedARlogs: [],
      logItems: [],
      logLoading: false,
      disableSwitch: null,
      selectedARItems: [],
      arLoading: false,
      arSearch: '',
      dataToCreateRule: {},
      fullscreenLoader: null,
      arItems: [],
      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage: '',
      appleMsgs : []
    };
  },
  computed: {
    logHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('aRules.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          // {
          //   text: this.$t('aRules.createdAt'),
          //   value: 'createdOn',
          //   default: 'NA',
          // },
          { text: this.$t('aRules.status'), value: 'logStatus', default: 'NA' },
          {
            text: this.$t('aRules.completed'),
            value: 'updatedOn',
            default: 'NA',
          },
          // {
          //   text: this.$t('aRules.actionFreq'),
          //   value: 'ruleFreq',
          //   default: 'NA',
          // },
          // { text: 'Actions', value: 'actions' },
        ];
      },
    },

    items: {
      cache: false,
      get() {
        return [this.$t('aRules.rules'), this.$t('aRules.logs')];
      },
    },

    arHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('signinSlackBtn.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          {
            text: this.$t('aRules.createdFor'),
            value: 'arFor.forType',
            default: 'NA',
          },
          { text: this.$t('aRules.status'), value: 'arStatus', default: 'NA' },
          {
            text: this.$t('aRules.chckFreq'),
            value: 'arSettings.checkFrequency',
            default: 'NA',
          },
          // {
          //   text: this.$t('aRules.actionFreq'),
          //   value: 'arActions.actionLimit',
          //   default: 'NA',
          // },
          { text: this.$t('aRules.enableDisable'), value: 'disable', sortable: false },
          { text: this.$t('aRules.actions'), value: 'actions', sortable: false },
        ];
      },
    },

    // arItems: {
    //   cache: false,
    //   get() {
    //     return [
    //       {
    //         name: 'Rule 1',
    //         createdOn: 'Date on',
    //         action: 'Email Notification',
    //         status: 'Running',
    //         checkFreq: 'Every 3 Hrs',
    //         actionFreq: 'Every 12 Hrs',
    //       },
    //     ];
    //   },
    // },
  },
  methods: {
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    decisionOkAction(event) {
      //console.log(event);
      this.decisionAlertFlag = false;
      const id = event.id;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.arItems = this.arItems.filter((x) => x._id != id);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoDeleteMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(
            this.$t('successMsg.requestFailMsg')
          );
        });
    },
    getAllRules() {
      this.arLoading = true;
      this.arItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES,
          variables: {
            teamId: 'none',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (
            res.data.getAllRules == null ||
            res.data.getAllRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          this.arItems = res.data.getAllRules;
          this.arLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    getAllLogs() {
      this.logLoading = true;
      this.logItems = [];

      this.$apollo
        .query({
          query: GET_ALL_LOGS,
          variables: {
            teamId: 'none',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          //console.log(res.data);
          if (res.data.getAlllogs == null || res.data.getAlllogs == undefined) {
            //console.log('No data');
            return;
          }
          this.logItems = res.data.getAlllogs;
          this.logItems = this.logItems.map(e => {
            const o = {
              createdOn : e.createdOn.toString().substr(0,10),
              logId: e.logId,
              logStatus: e.logStatus,
              ruleFreq: e.ruleFreq,
              ruleId: e.ruleId,
              ruleName: e.ruleName,
              updatedOn: 'On ' +e.updatedOn.toString().substr(0,10) + ' At ' + e.updatedOn.toString().substr(11,5),
              _id: e._id,
              data: e.data
            }
            return o
          })
          //console.log(this.logItems)
          this.logLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.logLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    dataFromCreateRule(e) {
      //console.log(e)
      this.fullscreenLoader = this.$loading.show();
      if (e) {
        this.getAllRules();
        updateCLF('createRule', this)
      }
      this.fullscreenLoader.hide();
    },
    callCreateRule() {
      const names = this.arItems.map(e => e.ruleName.toLowerCase().trim()) || []
      this.dataToCreateRule = {
        mode: 1,
        now: Date.now(),
        names: names
      };
    },
    editARRule(item) {
      //console.log('editARRule',item);
      const names = this.arItems.map(e => e.ruleName.toLowerCase().trim()) || []
      this.dataToCreateRule = {
        mode: 2,
        now: Date.now(),
        item: item,
        names: names
      };
    },
    deleteARRule(id) {
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        id: id,
        from: 'deleteRule',
        now: Date.now(),
      };
    },
    viewARRule(item) {
      console.log('viewARRule');
    },
    disableARRule(id) {
      //console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DISABLE_AR_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoRuleDisSuccessMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(
            this.$t('successMsg.requestFailMsg')
          );
        });
    },
    enableARRule(id) {
      //console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: ENABLE_AR_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoRuleEnableMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(
            this.$t('successMsg.requestFailMsg')
          );
        });
    },
    deleteARLog(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_LOG,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    showLogStatusMsg(item){
      //console.log(item.data)
      const a = []
      this.appleMsgs = []
      if(item.logStatus === 'SLACKSENT' || item.logStatus === 'SLACKFAIL'){
        this.infoAlertTitle = 'Messages from Slack!!!';
        if(item.data.error === 'not_in_channel'){
          a.push('The Search Ads Maven App is not added to the specified channel')
        }else if(item.data.error === 'channel_not_found'){
          a.push("Couldn't find the specified channel - might be deleted")
        }else if(item.data.error === 'invalid_auth'){
          a.push("The Search Ads Maven App is not authorized to post to the specified channel")
        }else if(item.data.error === 'missing_scope'){
          a.push("The Search Ads Maven App does not have permissions to post files to the specified channel")
        }else{
          a.push("Unable to post report to the specified channel")
        } 
      }else if(item.logStatus === 'EMAILSENT' || item.logStatus === 'EMAILFAIL'){
        this.infoAlertTitle = 'Messages!!!';
        a.push("Unable to send report to the specified email id")
      }else {
        this.infoAlertTitle = 'Messages from Apple!!!';
        if(item.data.error.errors instanceof Array){
          const errs = item.data.error.errors
          errs.map(err => a.push(err.message))
        }else if(item.data.error.errors instanceof Object){
          const err = item.data.error.errors
          if(err) a.push(err.message)
          else a.push("Unable to validate the specified changes")
        }else {
          a.push("Unable to validate the specified changes")
        }
      }
      this.infoAlertFlag = true;
      
      //this.infoAlertMessage = a
      this.appleMsgs = a
    },
  },
  mounted() {
    this.locale = localStorage.getItem('locale') || 'en'
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode
    });
    this.getAllRules();
    this.getAllLogs();
    updateCLF('automationRules', this)
  },
};
</script>
